import * as React from "react";
import { Wrapper } from "./Shop.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";

const Shop = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>Shop</h1>
    </div>
  );
};

export default Shop;
